<template>
  <div class="pages">
    <h1>Editar notícia</h1>
    <button class="btn delete-btn" v-on:click="deleteItem" :disabled="deleting">{{ deleting ? 'Excluindo...' : 'Excluir' }}</button>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <p v-if="loading">Carregando dados...</p>
    <div class="wrapper" v-if="!loading">
      <div>
        <label for="title">Título</label>
        <input type="text" id="title" v-model="item.title" placeholder="Título" v-on:keyup="formatURL">
      </div>
      <div>
        <label for="url">URL <small>ex: titulo-da-noticia</small></label>
        <input type="text" id="url" v-model="item.url" v-on:keyup="formatURL" placeholder="URL">
      </div>
      <div>
        <label for="pdf">PDF</label>
        <input type="file" id="pdf" v-on:change="pdfChanged" accept="application/pdf" ref="pdf">
        <p v-if="savingPDF">Fazendo upload...</p>
        <a v-if="item.pdf" target="_blank" :href="$mediaBaseUrl + item.pdf" :alt="item.title">Ver arquivo</a>
      </div>
      <div>
        <label for="date">Data <small>dd/mm/yyyy</small></label>
        <input type="text" id="date" v-model="item.date" placeholder="dd/mm/yyyy">
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="saving">{{ saving ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'edititem',
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      savingImg: false,
      savingPDF: false,
      itemId: this.$route.params.id,
      item: {
        title: '',
        url: '',
        date: '',
        pdf: '',
      },
      initData: {
        blocks: []
      },
    }
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'newsletters/item/' + this.itemId)
            .then(response => {
              self.item = response.data.item;
              self.initData = self.item.content;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.saving = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);

        axios.put(this.$apiUrl + 'newsletters/item/' + this.itemId, itemToSave)
                .then(response => {
                  self.saving = false;
                  this.$alert(response.data.message, 'Sucesso', 'success').then(() => {});
                })
                .catch(error => {
                  this.$alert(error.response.data.message, 'Erro', 'error');
                  console.log(error.response.data.error);
                  self.saving = false;
                });
      }
    },
    deleteItem() {
      this.$confirm('Certeza que deseja excluir?', 'Excluir', 'question', {confirmButtonText: 'Sim', cancelButtonText: 'Cancelar'})
              .then(() => {
                this.deleting = true;
                let self = this;

                axios.delete(this.$apiUrl + 'newsletters/item/' + this.itemId)
                        .then(response => {
                          self.deleting = false;
                          this.$alert(response.data.message, 'Excluído', 'success').then(() => {
                            self.$router.push('/newsletter');
                          });
                        })
                        .catch(error => {
                          this.$alert(error.response.data.message, 'Erro', 'error');
                          console.log(error.response.data.error);
                          self.deleting = false;
                        });
              })
              .catch(() => {});
    },
    async imgChanged() {
      let self = this;
      let file = this.$refs.img.files[0];
      self.savingImg = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.image = response.data.file.key;
                  self.savingImg = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingImg = false;
                });
      }
    },
    async pdfChanged() {
      let self = this;
      let file = this.$refs.pdf.files[0];
      self.savingPDF = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.pdf = response.data.file.key;
                  self.savingPDF = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingPDF = false;
                });
      }
    },
    formatURL() {
      this.item.url = this.item.title
                                .toString()                     // Cast to string
                                .toLowerCase()                  // Convert the string to lowercase letters
                                .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
                                .trim()                         // Remove whitespace from both sides of a string
                                .replace(/\s+/g, '-')           // Replace spaces with -
                                // eslint-disable-next-line no-useless-escape
                                .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                                // eslint-disable-next-line no-useless-escape
                                .replace(/\-\-+/g, '-');
    },
    save(response) {
      this.item.content = response;
    },
    onReady() {
      this.$refs['editor_content'].save();
    },
    onChange() {
      this.$refs['editor_content'].save();
    }
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }
  }

  .delete-btn {
    background-color: #b13336;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(#b13336, 20%);
    }
  }
</style>
